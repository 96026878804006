/**
 * Global Scripts
 */

import jsEnabled from './_jsEnabled.js';
import navToggle from './_navToggle.js';
import navMenu from './_navMenu.js';
import formSearchHeader from './_formSearchHeader.js';
import headerBanner from './_headerBanner.js';
import websiteFeedback from './_websiteFeedback.js';

export default () => {
    jsEnabled();
    navToggle();
    navMenu();
    formSearchHeader();
    headerBanner();
    websiteFeedback();
}
