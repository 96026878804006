/**
 * Anchor Links
 */

export default (context = 'main') => {
    const searchIn = document.querySelector(context);

    // Bail if nothing to search in
    if (!searchIn) return;

    // Get headings
    const headings = searchIn.querySelectorAll('h1, h2, h3, h4, h5, h6');

    // Bail if no headings
    if (!headings) return;

    // Loop through heeadings
    for (let heading of headings) {

        // Check for heading link
        let link = heading.querySelector('a');

        // Only proceed with heading anchor links
        if (link && link.hasAttribute('href') && link.getAttribute('href').startsWith('#')) {
            let container           = document.createElement('div'),
                icon                = document.createElement('i'),
                iconHeight          = 1.5, // width in rem
                iconWidth           = 1.875 + 0.5, // width plus space in rem
                headingMarginBottom = parseFloat(window.getComputedStyle(heading).marginBottom),
                headingMarginTop    = parseFloat(window.getComputedStyle(heading).marginTop),
                linkHeight          = link.offsetHeight / 16,
                linkLeft            = link.getBoundingClientRect().left / 16; // convert to rem

            // Assign container classes
            container.classList.add('alignfull', 'is-heading-anchor-container');

            // Move heading top and bottom margin to container
            container.style.marginBottom = headingMarginBottom + 'px';
            container.style.marginTop = headingMarginTop + 'px';
            heading.style.marginBottom = 0;
            heading.style.marginTop = 0;

            // Assign icon classes and position icon
            icon.classList.add('fa-solid', 'fa-link');
            icon.style.left = linkLeft - iconWidth + 'rem';
            icon.style.marginTop = (linkHeight - iconHeight) / 2 + 'rem';

            // Place container before heading
            heading.before(container);

            // Make sure there's room for an icon and viewport width has hover potential
            if (context !== '.edit-post-visual-editor' && linkLeft > iconWidth && window.innerWidth >= 1280) {
                container.appendChild(icon);
            }

            // Move heading into container
            container.appendChild(heading);
        }
    }
}
