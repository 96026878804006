/**
 * Header Search Form JS
 */

export default () => {
    /**
     * Get form elements
     */
    const forms = document.querySelectorAll('#jmulibSearchHeader');

    for (let wrap of forms) {
        formSearchHeader(wrap);
    }
}

function formSearchHeader(wrap) {
    const   toggle      = wrap.querySelector('.toggle'),
            form        = wrap.querySelector('#jmulibSearchHeaderForm'),
            inputs      = wrap.querySelector('#jmulibSearchHeaderDynamicInputs'),
            scope       = wrap.querySelector('#jmulibSearchHeaderScope'),
            query       = wrap.querySelector('#jmulibSearchHeaderQuery'),
            submit      = wrap.querySelector('#jmulibSearchHeaderSubmit'),
            fields      = [scope, query, submit];


    /**
     * Match input placeholder to scope
     */
    function updatePlaceholder() {
        query.setAttribute('placeholder', 'Search ' + scope.options[scope.selectedIndex].text);
    }

    /**
     * Toggle form
     */

    // Open search helper
    function openSearch() {
        wrap.setAttribute('open', '');
        toggle.setAttribute('aria-expanded', 'true');
        fields.forEach(field => {
            field.setAttribute('tabindex', '0');
        });
    }

    // Close search helper
    function closeSearch() {
        wrap.removeAttribute('open');
        toggle.setAttribute('aria-expanded', 'false');
        fields.forEach(field => {
            field.setAttribute('tabindex', '-1');
        });
    }

    // Open/close search on toggle button click
    toggle.onclick = () => {
        if (toggle.getAttribute('aria-expanded') === 'true') {
            closeSearch();
        } else {
            openSearch();

            // Update placeholder
            // Run on open click because browser back button loads previous (not initial) value of the scope select field
            updatePlaceholder();
        }
    }

    // Close search with Escape key
    wrap.onkeydown = function(event) {
        let isEscape = false;

        // Cover different Escape values
        if ('key' in event) {
            isEscape = event.key === 'Escape' || event.key === 'Esc';
        } else {
            isEscape = event.keyCode === 27;
        }

        if (isEscape) {
            closeSearch();

            // Also focus on toggle button
            toggle.focus();

            // Don't propagate
            event.stopPropagation();
        }
    }

    // Close search when focusing out of it
    wrap.addEventListener('blur', (event) => {
        let searchContainsFocus = wrap.contains(event.relatedTarget);

        if (!searchContainsFocus) {
            closeSearch();
            document.activeElement.blur();
        }
    }, true);


    /**
     * Input and attribute data
     */
    // Form attributes
    const formAttributes = {
        "primo": {
            "action": "https://search.lib.jmu.edu/discovery/search",
            "enctype": "application/x-www-form-urlencoded; charset=utf-8"
        },
        'databases': {
            "action": "https://guides.lib.jmu.edu/az.php"
        },
        'journals': {
            "action": "https://search.lib.jmu.edu/discovery/jsearch"
        },
        "guides": {
            "action": "https://guides.lib.jmu.edu/srch.php"
        },
        "news": {
            "action": "https://www.lib.jmu.edu/search/"
        }
    };

    // Inputs for all Primo searches
    const primoInputs = {
        "query": "any,contains,",
        "vid": "01JMU_INST:01JMU"
    };

    // Inputs for all Primo searches that aren't music
    const primoNotMusicInputs = {
        "mode": "basic",
        "displayMode": "full",
        "bulkSize": "10",
        "highlight": "true",
        "dum": "true",
        "displayField": "all",
        "pcAvailabilityMode": "true"
    }

    // Scope sepcific inputs
    const scopeInputs = {
        "collections": {
            "searchScope": "MyInst_and_CI",
            "tab": "Everything"
        },
        "books": {
            "searchScope": "MyInst_and_CI",
            "tab": "Everything",
            "mfacet": [
                "rtype,include,books,1"
            ]
        },
        "music": {
            "searchScope": "MyInst_and_CI",
            "tab": "Everything",
            "mfacet": [
                "rtype,include,scores,1"
            ]
        },
        "video": {
            "searchScope": "MyInstitution",
            "tab": "LibraryCatalog",
            "mfacet": [
                "rtype,include,videos,1",
                "tlevel,include,available_p,2"
            ]
        },
        "databases": {},
        "journals": {
            "tab": "jsearch_slot",
            "journals": "any,"
        },
        "reserves": {
            "searchScope": "CourseReserves",
            "tab": "CourseReserves"
        },
        "special": {
            "searchScope": "MyInstitution",
            "tab": "LibraryCatalog",
            "mfacet": [
                "location_code,include,6271–112057590006271–carrspec,1",
                "location_code,include,6271–112057590006271–carrspecst,1",
                "location_code,include,6271–112057590006271–carrspecrf,1"
            ]
        },
        "guides": {},
        "news": {
            "post_type": "post"
        }
    };

    // Set initial search scope and placeholder value
    let searchScope = scope.value,
        primoSearch = !['news', 'databases', 'guides'].includes(searchScope);


    /**
     * Modify form when searchScope select field is changed
     */
    scope.addEventListener('change', event => {
        // Update scope
        searchScope = event.target.value;
        primoSearch = !['news', 'databases', 'guides'].includes(searchScope);


        /**
         * Replace form element attributes when scope select field is changed
         */
        if (searchScope === 'journals') {
            form.setAttribute('action', formAttributes[searchScope]['action']);
            form.setAttribute('enctype', formAttributes['primo']['enctype']);
        } else if (primoSearch) {
            form.setAttribute('action', formAttributes['primo']['action']);
            form.setAttribute('enctype', formAttributes['primo']['enctype']);
        } else {
            form.setAttribute('action', formAttributes[searchScope]['action']);
            form.removeAttribute('enctype');
        }


        /**
         * Replace search field placeholder when scope select field is changed
         */
        updatePlaceholder();


        /**
         * Replace dynamic inputs when scope select field is changed
         */
        // Start new inputs
        const newInputs = document.createDocumentFragment();

        // Primo searches
        if (primoSearch) {
            // Add global Primo inputs
            for (let primoInput in primoInputs) {

                // Create input element
                let input = document.createElement('input');
                Object.assign(input, {
                    name: primoInput,
                    defaultValue: primoInputs[primoInput],
                    hidden: true
                });

                // Add input element to fragment
                newInputs.appendChild(input);
            }

            // Add Primo not music search inputs
            if (searchScope !== 'music') {
                for (let primoInput in primoNotMusicInputs) {

                    // Create input element
                    let input = document.createElement('input');
                    Object.assign(input, {
                        name: primoInput,
                        defaultValue: primoNotMusicInputs[primoInput],
                        hidden: true
                    });

                    // Add input element to fragment
                    newInputs.appendChild(input);
                }
            }
        }

        // Loop through scope specific inputs
        for (let inputName in scopeInputs[searchScope]) {

            // mfacet attributes need an additional loop because there could be more than one
            if (inputName === 'mfacet') {
                scopeInputs[searchScope]['mfacet'].forEach(value => {

                    // Create input element
                    let input = document.createElement('input');
                    Object.assign(input, {
                        name: 'mfacet',
                        defaultValue: value,
                        hidden: true
                    });

                    // Add input element to fragment
                    newInputs.appendChild(input);
                });

            } else {
                // Create input element
                let input = document.createElement('input');
                Object.assign(input, {
                    name: inputName,
                    defaultValue: scopeInputs[searchScope][inputName],
                    hidden: true
                });

                // Add input element to fragment
                newInputs.appendChild(input);
            }
        }

        // Replace all existing dynamic inputs with new inputs
        inputs.innerHTML = '';
        inputs.appendChild(newInputs);
    });


    /**
     * Modify search query when form is submitted
     */
    form.addEventListener('submit', () => {
        // Get search text
        const searchText = query.value;

        // Primo searches
        if (primoSearch) {

            // Replace commas with spaces
            searchText.replace(/[,]/g, ' ');

            // Strip out special characters
            searchText.replace(/[!+;#%?:]/g, "");

            // Encode for url
            //searchText = encodeURIComponent(searchText);

            // Add to query input
            form.query.value += searchText;

            // Remove name attribute from input name="q" so it's not sent in the GET request
            query.removeAttribute('name');
        }

        // Journal searches
        if (searchScope === 'journals') {
            form.journals.value += searchText;
        }

        // News searches
        if (searchScope === 'news') {
            // Append query string to form action attribute path
            form.setAttribute('action', form.getAttribute('action') + searchText);

            // Remove name attribute from input name="q" so it's not sent in the GET request
            query.removeAttribute('name');
        }
    });
}
