/**
 * Navigation Menus JS
 * Enhances keyboard interaction for menus nested in a <nav> element with a data-navMenu attribute
 *
 * Impelements the following rules
 * 1. When a menu closes, also close its descendants
 * 2. When a menu opens, also open its ancestors and close its siblings
 *
 * @link https://www.w3.org/WAI/ARIA/apg/patterns/disclosure/examples/disclosure-navigation/
 */
/* eslint-disable no-inner-declarations */

export default () => {

    /**
     * Get <nav> elements
     * Apply to navs with a data-navMenu attribute
     */
    const  navs = document.querySelectorAll('nav[data-navMenu]');

    for (let nav of navs) {
        const   allSubmenuButtons   = nav.querySelectorAll('.submenu-toggle'),
                allSubmenus         = nav.querySelectorAll('.submenu'),
                allMenus            = nav.querySelectorAll('.menu');

        /**
         * Open/close submenus on button click
         */
        nav.addEventListener('click', (event) => {
            if (event.target.tagName === 'BUTTON' && !event.target.classList.contains('nav-toggle')) {
                let button      = event.target,
                    submenu     = button.nextElementSibling,
                    open        = submenu.classList.contains('open');

                // Opening
                if (!open) {
                    openMenu(button);

                // Closing
                } else {
                    closeMenu(button);
                }

                // Toggle open class on container nav
                // Disables open on hover
                if (isLevel1(submenu)) {
                    nav.classList.toggle('open', !open);
                }
            }
        });


        /**
         * Open menu if it contains a link with aria-current="page"
         * <nav> element must have a data-navMenu-open-current-ancestors attribute
         */
        if (nav.hasAttribute('data-navMenu-open-current-ancestors')) {
            let allLinks = nav.querySelectorAll('a');

            for (let link of allLinks) {
                let isSubmenuLink = false,
                    originalLink = link;

                // Only if the link is in a submenu
                while (!link.parentElement.classList.contains('level-1')) {
                    if (link.parentElement.classList.contains('submenu')) {
                        isSubmenuLink = true;
                        break;
                    }

                    // Move loop pointer
                    link = link.parentElement;
                }

                // Reset link
                link = originalLink;

                if (link.hasAttribute('aria-current') && isSubmenuLink) {
                    let button = link.closest('.submenu').previousElementSibling;

                    openMenu(button);
                }
            }
        }


        /**
         * Escape key closes current submenu and returns focus to its button
         */
        for (let submenu of allSubmenus) {
            submenu.onkeydown = function(event) {
                let button = submenu.previousElementSibling,
                    isEscape = false;

                // Cover different Escape values
                if ('key' in event) {
                    isEscape = event.key === 'Escape' || event.key === 'Esc';
                } else {
                    isEscape = event.keyCode === 27;
                }

                if (isEscape) {
                    // Focus on previous button
                    button.focus();

                    // Close self, close descendants
                    closeMenu(button);

                    // Focusing on level-1 button should remove open class from nav element
                    if (isLevel1(submenu)) {
                        nav.classList.remove('open');
                    }

                    // Only fire for submenu containing focus
                    event.stopPropagation();
                }
            };
        }


        /**
         * Close all submenus when focusing outside the nav
         */
        nav.addEventListener('blur', (event) => {
            let navContainsFocus    = nav.contains(event.relatedTarget);

            if (!navContainsFocus) {
                nav.classList.remove('open');
                allSubmenuButtons.forEach(button => closeMenu(button));
            }
        }, true);


        /**
         * Home/end keys move focus to first/last focusable item
         */
        for (let menu of allMenus) {
            menu.onkeydown = function(event) {
                if ('key' in event && ['Home', 'End'].includes(event.key)) {
                    let listItems = menu.querySelectorAll(':scope > li > a, :scope > li > button');
                    event.preventDefault();
                    event.stopPropagation();
                    event.key === 'Home' ? listItems[0].focus() : listItems[listItems.length - 1].focus();
                }
            };
        }
    }


    /**
     * Helper Functions
     */
    // Open Menu
    function openMenu(button)
    {
        openButton(button);
        openSubmenu(button.nextElementSibling);
        openAncestors(button);
        closeSiblings(button);
    }

    // Close Menu
    function closeMenu(button)
    {
        closeButton(button);
        closeSubmenu(button.nextElementSibling);
        closeDescendants(button);
    }

    // Open button
    function openButton(button)
    {
        button.setAttribute('aria-expanded', 'true');
    }

    // Close button
    function closeButton(button)
    {
        button.setAttribute('aria-expanded', 'false');
    }

    // Open submenu
    function openSubmenu(submenu)
    {
        submenu.classList.add('open');
    }

    // Close submenu
    function closeSubmenu(submenu)
    {
        submenu.classList.remove('open');
    }

    // Get ancestor submenus
    function getAncestorSubmenus(button)
    {
        let ancestorSubmenus = [];

        while (button.parentElement) {
            if (button.parentElement.classList.contains('submenu')) {
                ancestorSubmenus.push(button.parentElement);
            } else if (button.parentElement.classList.contains('level-1')) {
                break;
            }
            button = button.parentElement;
        }

        return ancestorSubmenus;
    }

    // Open ancestors
    function openAncestors(button) {
        let ancestorSubmenus = getAncestorSubmenus(button);

        for (let submenu of ancestorSubmenus) {
            let button = submenu.previousElementSibling;

            openButton(button);
            openSubmenu(submenu);
        }

        button.closest('nav').classList.add('open');
    }

    // Close descendants
    function closeDescendants(button) {
        let submenu             = button.nextElementSibling,
            descendantButtons   = submenu.querySelectorAll('button'),
            descendantSubmenus  = submenu.querySelectorAll('.submenu');

        descendantButtons.forEach(b => closeButton(b));
        descendantSubmenus.forEach(s => closeSubmenu(s));
    }

    // Close siblings
    function closeSiblings(button) {
        let submenu         = button.nextElementSibling,
            parentMenu      = button.closest('.menu'),
            siblingButtons  = parentMenu.querySelectorAll('button'),
            siblingSubmenus = parentMenu.querySelectorAll('.submenu');

        siblingButtons.forEach(b => b !== button && closeButton(b));
        siblingSubmenus.forEach(s => s !== submenu && closeSubmenu(s));
    }

    // Is Level 1
    function isLevel1(submenu)
    {
        return submenu.closest('.menu').classList.contains('level-1');
    }
}
