/**
 * WordPress Scripts
 */

import anchorLink from './partials/_anchorLink.js';
import base from './partials/_base.js';
import fontAwesome from './partials/_fontawesome.js';

base();
fontAwesome();

window.addEventListener('load', () => {
    anchorLink();
});
