/**
 * Header Banner for non-WordPress environments
 * Displays the first published News post from both the Emergency News and Emergency Closures categories
 */

// Triangle icon
// @link https://discourse.roots.io/t/bud-js-import-svg-for-inline-html-injected-via-js
import icon from '@src/images/triangle-exclamation.svg?source'

export default async () => {

    // Don't run in WordPress environments
    // WordPress displays header banner via templates/partials/header-banner.php
    if (document.documentElement.classList.contains('env-wp')) return

    try {
        // Get header and bail if no header
        const header = document.getElementById('jmulibSiteHeader')
        if (!header) return

        // Get all posts in the Emergency News and Emergency Closures categories
        const   bannerPosts     = [],
                eNewsFetch      = await fetch('https://www.lib.jmu.edu/wp-json/wp/v2/posts?categories=9&_fields=title,link'),
                eNewsPosts      = await eNewsFetch.json(),
                eClosuresFetch  = await fetch('https://www.lib.jmu.edu/wp-json/wp/v2/posts?categories=41&_fields=title,link'),
                eClosuresPosts  = await eClosuresFetch.json()

        // Add first post of each category to bannerPosts array
        if (eNewsPosts.length > 0) bannerPosts.push(eNewsPosts[0])
        if (eClosuresPosts.length > 0) bannerPosts.push(eClosuresPosts[0])

        // Bail if no posts
        if (bannerPosts.length === 0) return

        // Start banner
        // srOnly is only needed if mainScoped.css styles are used
        const   headerBanner    = document.createElement('div'),
                srOnly          = '.sr-only{height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px;clip:rect(0, 0, 0, 0);border-width 0;white-space:nowrap;}'

        let bannerContent = '<style>' + srOnly + '</style><div style="margin:0 auto;max-width:var(--jmulib-container-base">' + icon + '<div class="header-banner-content">'

        // Loop through bannerPosts array
        bannerPosts.forEach(post => {
            const linkText = 'Read More<span class="sr-only"> about ' + post.title.rendered + '</span>'
            bannerContent += '<div>' + post.title.rendered + ' – ' + '<a href="' + post.link + '">' + linkText + '</a>' + '</div>'
        })

        // End banner
        bannerContent += '</div>' + icon + '</div>'
        headerBanner.setAttribute('id', 'jmulibHeaderBanner')
        headerBanner.innerHTML = bannerContent

        // Insert banner after header
        header.after(headerBanner)

    } catch (error) {
        console.warn('Header banner error: ' + error) // eslint-disable-line
    }
}
