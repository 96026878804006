/**
 * Navigation Toggle
 * Activate by adding data-nav-toggle="[nav element id]" to the container element
 */

export default () => {
    const navContainers = document.querySelectorAll('[data-nav-toggle]');

    for (let navContainer of navContainers) {
        let navId       = navContainer.getAttribute('data-nav-toggle'),
            navClass    = navId.replace(/[A-Z]/g, m => "-" + m.toLowerCase()) + '-open',
            button      = document.getElementById(navId + 'Toggle'),
            nav         = document.getElementById(navId);

        // Toggle nav open/close
        button.addEventListener('click', () => {
            document.body.classList.toggle(navClass);
            button.classList.toggle('open');
            nav.classList.toggle('open');

            if (button.classList.contains('open')) {
                button.setAttribute('aria-expanded', 'true');
            } else {
                button.setAttribute('aria-expanded', 'false');
            }
        });

        // Escape key closes nav and returns focus to its button
        nav.onkeydown = function(event) {
            let isEscape = false;

            if ('key' in event) {
                isEscape = event.key == 'Escape' || event.key == 'Esc';
            } else {
                isEscape = event.keyCode == 27;
            }
            if (isEscape) {
                // Focus on previous button
                button.focus();

                // Close nav
                document.body.classList.remove(navClass);
                button.classList.remove('open');
                button.setAttribute('aria-expanded', 'false');
                nav.classList.remove('open');
            }
        };

        // Close nav when clicking outside it
        navContainer.addEventListener('blur', (event) => {
            let navContainsFocus = navContainer.contains(event.relatedTarget);

            if (!navContainsFocus) {
                document.body.classList.remove(navClass);
                button.classList.remove('open');
                button.setAttribute('aria-expanded', 'false');
                nav.classList.remove('open');
            }
        }, true);
    }
}
