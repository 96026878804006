/**
 * Website Feedback
 * Adds current url to the link's source parameter
 */

export default () => {
    const   currentUrl      = encodeURIComponent(window.location.href),
            feedbackButton  = document.getElementById('jmulibWebsiteFeedback');

    if (feedbackButton) {
        let feedbackUrl = feedbackButton.getAttribute('href');
        feedbackButton.setAttribute('href', feedbackUrl += '?source=' + currentUrl);
    }
}
